export const leagues = [{
  code: "BLB"
}, {
  code: "MH3"
}, {
  code: "OTJ"
}, {
  code: "Chaos3"
}, {
  code: "MKM"
}, {
  code: "KTK"
}, {
  code: "LCI"
}, {
  code: "XLN"
}, {
  code: "WOE"
}, {
  code: "LTR"
}, {
  code: "NEO-2"
}, {
  code: "MOM",
}, {
  code: "SIR",
}, {
    code: "ONE",
}, {
  code: "Planechase"
}];

export const codeToUrl = leagueCode => `https://trout.rocks/agl/data/${leagueCode}.json`;

export const defaultLeague = leagues[0].code;

// Container for the app that routes info between components and actions/reducers
// More on the react-redux pattern being used here: https://react-redux.js.org/introduction/basic-tutorial

import { connect } from 'react-redux';

import AppComponent from '../_components/App'

const mapStateToProps = state => {
  return {};
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}

const App = connect(mapStateToProps, mapDispatchToProps)(AppComponent);

export default App;
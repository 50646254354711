// Reducer for the Probability component/action. 
// More on the react-redux pattern being used here: https://react-redux.js.org/introduction/basic-tutorial
import { probabilityConstants } from '../_constants/probability.constants';

const probabilityList = (state = {}, action) => {
  switch (action.type) {
    case probabilityConstants.PROBABILITY_REQUEST:
      return {
        loading: true
      };
    case probabilityConstants.PROBABILITY_SUCCESS:
      console.log('reducer processing PROBABILITY_SUCCESS, returning payload');
      return {
        probabilityList: action.probabilityList
      };
    case probabilityConstants.PROBABILITY_FAILURE:
      console.log('reducer processing PROBABILITY_FAILURE hit error');
      console.log(action.error);
      return {
        error: action.error
      };
    default:
      return state
  }
}

export default probabilityList;
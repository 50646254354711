import React from 'react';
import { Button, Table } from 'react-bootstrap';
import Select from 'react-select';
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
import { InView } from 'react-intersection-observer';

  // first, descending on points
const standingsOrder = (a, b) => b.currentWins - a.currentWins
  // second, ascending on games played (= descending on match win rate)
  || (a.currentWins + a.currentLosses) - (b.currentWins + b.currentLosses)
  // third, descending on opponent match win rate
  || b.currentOMW - a.currentOMW
  // fourth, alphabetical by name
  || a.name.localeCompare(b.name);

////////////////////
// Component that encapsulates the main AGL Probability chart
////////////////////
class AGLProbabilities extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      curPlayerFilter: [],
      expandRank: false,
    };

    this.clearFilter = this.clearFilter.bind(this);
    this.updatePlayerFilter = this.updatePlayerFilter.bind(this);
    this.toggleRankCollapse = this.toggleRankCollapse.bind(this);
  }

  clearFilter() {
    this.setState({curPlayerFilter: null});
  }

  updatePlayerFilter(event) {
    let curFilter = [];

    event.forEach(element => {
      curFilter.push(element);
    });

    this.setState({curPlayerFilter: curFilter});
  }

  toggleRankCollapse() {
    this.setState({ expandRank: !this.state.expandRank });
  }

  render() {
    const { probabilityList } = this.props;
    let resultArray = [];
    let playerList = [];
    let iterations = 0;
    let historyIterations = 0;
    let maxMatchesSoFar = 0;
    let asOf = new Date(1999, 11, 31).toLocaleString(undefined, { dateStyle: 'long', timeStyle: 'short' });
    let topCounts = [8, 16, 32];
    let isTeamBased = false;
    let expandMatchLimit = 25;

    if (probabilityList?.probabilityList?.results) {
      historyIterations = probabilityList.probabilityList.historyIterations;
      isTeamBased = probabilityList.probabilityList.isTeamBased;
      var utcDate = new Date(probabilityList.probabilityList.asOf);
      asOf = utcDate.toLocaleString(undefined, { dateStyle: 'long', timeStyle: 'short' });
      resultArray = probabilityList.probabilityList.results;
      iterations = probabilityList.probabilityList.iterations;
      const matchCount = probabilityList.probabilityList.matchCount ?? 30;
      expandMatchLimit = matchCount - 5;

      if (isTeamBased) topCounts = [4, 8, 16];

      resultArray = resultArray.sort(standingsOrder);
      for (const element of resultArray) {
        if ((element.currentWins + element.currentLosses) > maxMatchesSoFar) {
          maxMatchesSoFar = element.currentWins + element.currentLosses;
        }

        element.topCounts = topCounts.map(() => 0);

        if (element.rankCounts) {
          for (const rankCount of element.rankCounts) {
            for (let i = 0; i < topCounts.length; i++) {
              if (rankCount[0] <= topCounts[i]) element.topCounts[i] += rankCount[1];
            }
          }
        }
      }

      resultArray = resultArray.sort((a,b) => b.topCounts[0] - a.topCounts[0] || standingsOrder(a, b));
      playerList = resultArray.map((item) => ({value:item.name, label:item.name}));
      playerList = playerList.sort((a,b) => a.value.localeCompare(b.value));

      if (this.state.curPlayerFilter && this.state.curPlayerFilter.length > 0) {
        resultArray = resultArray.filter((element) => this.state.curPlayerFilter.map((item) => item.value).includes(element.name));
      }
    }

    return (
      <div className="mainContent">
        {probabilityList?.probabilityList?.loading && <em>Loading...</em>}
        {resultArray?.length > 0 &&
          <div>
            <div>Data last updated: <b>{asOf} (Local Time)</b></div>
            <div className="playerFilter">Player filter: 
              <Select 
                className="playerFilterDropdown" 
                options={playerList}
                onChange={this.updatePlayerFilter.bind(this)}
                isClearable={true}
                isMulti={true}
                value={this.state.curPlayerFilter}
              >
              </Select>
              <Button onClick={this.clearFilter.bind(this)}>Clear Filter</Button>
            </div>
            { maxMatchesSoFar >= expandMatchLimit && <div><Button onClick={this.toggleRankCollapse.bind(this)}>{this.state.expandRank ? <span>Collapse Rank Distribution</span> : <span>Expand Rank Distribution</span>}</Button></div>}
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>{isTeamBased ? 'Team' : 'Player'} Name</th>
                  <th>Wins</th>
                  <th>Losses</th>
                  <th>OMW</th>
                  <th>Top {topCounts[0]} % chance</th>
                  <th>Expected winnings</th>
                  <th>Rank distribution</th>
                  <th>Top {topCounts[0]} chance history</th>
                </tr>
              </thead>
              <tbody>
                {resultArray.map((item) => (
                  <tr key={item.arenaId}>
                    <td>{item.name}</td>
                    <td>{item.currentWins}</td>
                    <td>{item.currentLosses}</td>
                    <td>{Math.round(item.currentOMW * 10000)/100}%</td>
                    <td>{Math.round(item.topCounts[0] * 10000 / iterations)/100}%{item.topCounts[0] === iterations && ' 🔒'}</td>
                    <td>${Math.round(item.expectedWinnings)}</td>
                    <td>
                        { maxMatchesSoFar >= expandMatchLimit && this.state.expandRank
                        ? item.rankCounts.map((innerItem) => <div>#{innerItem[0]}: {Math.round(innerItem[1] * 100 / iterations)}%</div>)
                        : <div>
                            <div>Top {topCounts[1]}: {Math.round(item.topCounts[1] * 100 / iterations)}%</div>
                            <div>Top {topCounts[2]}: {Math.round(item.topCounts[2] * 100 / iterations)}%</div>
                          </div>
                        }
                    </td>
                    <td>
                      {item.top8History && 
                        <InView triggerOnce={true}>{({inView, ref}) => (
                          <div ref={ref} className="sparkline">
                            {inView &&
                              <Sparklines data={item.top8History} width={150} height={75} margin={5} min={0} max={historyIterations} >
                                <SparklinesLine color="blue" style={{fill:"none"}} />
                                <SparklinesSpots />
                              </Sparklines>
                            }
                          </div>
                        )}</InView>
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        }
      </div>
    );
  }
}

export default AGLProbabilities;

// Main reducer for the app overall.  It combines other reducers into a single object.  This routes information from actions back to components/containers
// More on the react-redux pattern being used here: https://react-redux.js.org/introduction/basic-tutorial

import {
  combineReducers,
} from 'redux';

import probabilityList from './probability.reducer';

const rootReducer = combineReducers({
  probabilityList,
});

export default rootReducer;

import React from 'react';
import { Navbar, Nav, Row, Col, Container } from 'react-bootstrap';

import AGLProbabilities from './AGLProbabilities.jsx';
import AGLRankStats from './AGLRankStats.jsx';
import AGLCutoffStats from './AGLCutoffStats.jsx';
import { leagues, defaultLeague } from '../_constants/leagues.constants';

////////////////////
// Component that encapsulates the main home page for the app
////////////////////
class HomePage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'Players',
      currentLeague: defaultLeague,
    }
  }

  navClick(nav, event) {
    console.log('setting current tab to ' + nav);
    this.setState({ currentTab: nav })
  }

  setClick(nav, event) {
    console.log('setting current set to ' + nav);
    this.setState({ currentLeague: nav })
    this.props.fetchProbabilities(nav);
  }

  componentDidMount() {
    // fetch the current probabilities
    this.props.fetchProbabilities(this.state.currentLeague);
  }

  render() {

    return (
      <Container className="mainContent">
        <Row>
          <Col>
            <Navbar bg="dark" variant="dark" expand="lg">
              <Container>
                <Navbar.Brand href="#home">AGL Probability Model</Navbar.Brand>
                <Nav fill id="basic-navbar-nav" variant="pills" activeKey={this.state.currentTab} className="justify-content-around col-6">
                  <Nav.Link eventKey="Players" onClick={this.navClick.bind(this, 'Players')}>Player Predictions</Nav.Link>
                  <Nav.Link eventKey="Ranks" onClick={this.navClick.bind(this, 'Ranks')}>Rank Predictions</Nav.Link>
                  <Nav.Link eventKey="Cutoffs" onClick={this.navClick.bind(this, 'Cutoffs')}>Cutoff Predictions</Nav.Link>
                </Nav>
                <Navbar.Text className="rightNav">Current League: <b>{defaultLeague}</b></Navbar.Text>
              </Container>
            </Navbar>
          </Col>
        </Row>
        <Row className="flex-lg-nowrap">
          <Col lg="auto">
            <Navbar bg="dark" variant="dark" expand="lg" className="verticalNav">
              <Container>
                <Nav fill bg="dark" variant="pills" className="flex-column" activeKey={this.state.currentLeague}>
                  { leagues.map(l => (
                    <Nav.Link eventKey={l.code} onClick={this.setClick.bind(this, l.code)}>{l.code}</Nav.Link>
                  ))}
                </Nav>
              </Container>
            </Navbar>
          </Col>
          <Col>
            {this.props.probabilityList &&
              <div className="rightContent">
                {this.state.currentTab === 'Players' ? <AGLProbabilities probabilityList={this.props.probabilityList} /> : false}
                {this.state.currentTab === 'Ranks' ? <AGLRankStats probabilityList={this.props.probabilityList} /> : false}
                {this.state.currentTab === 'Cutoffs' ? <AGLCutoffStats probabilityList={this.props.probabilityList} /> : false}
              </div>
            }
          </Col>
        </Row>
      </Container>
    );
  }
}

export default HomePage;
import { probabilityConstants } from '../_constants/probability.constants';
import { codeToUrl } from '../_constants/leagues.constants';

////////////////////
// Action that fetches the list of PD runs from azure table storage 
// More on the react-redux pattern being used here: https://react-redux.js.org/introduction/basic-tutorial
////////////////////
export function fetchProbabilities(leagueCode) {
  function request() { return { type: probabilityConstants.PROBABILITY_REQUEST }; }
  function success(probabilityList) { return { type: probabilityConstants.PROBABILITY_SUCCESS, probabilityList: probabilityList }; }
  function failure(error) { return { type: probabilityConstants.PROBABILITY_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    // default to planechase for now
    const statUrl = codeToUrl(leagueCode);

    fetch(statUrl, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          dispatch(
            failure(
              `Error: fetching AGL stats returned HTTP ${response.status}`
            )
          );
        }
        return response.json();
      })
      .then((json) => {
        dispatch(success(json));
      });
  };
}
// Container for the secondary AGL Rank Stats chart
// More on the react-redux pattern being used here: https://react-redux.js.org/introduction/basic-tutorial

import { connect } from 'react-redux';
import HomePageComponent from '../_components/HomePage';
import { fetchProbabilities } from '../_actions/GetProbabilityData';

const mapStateToProps = (state) => {
  return {
    probabilityList: state.probabilityList,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchProbabilities: (leagueCode) => {
      dispatch(fetchProbabilities(leagueCode));
    },
  };
};

const HomePage = connect(mapStateToProps, mapDispatchToProps)(HomePageComponent);

export default HomePage;

import React from 'react';
import { Table } from 'react-bootstrap';

////////////////////
// Component that encapsulates the secondary AGL Rank Stats chart
////////////////////
class AGLRankStats extends React.Component {

  render() {
    const { probabilityList } = this.props;
    let rankList = probabilityList;
    let rankWinCounts = [];
    let asOf = new Date(1999, 11, 31).toLocaleString(undefined, { dateStyle: 'long', timeStyle: 'short' });

    if (rankList?.probabilityList) {
      var utcDate = new Date(rankList.probabilityList.asOf);
      asOf = utcDate.toLocaleString(undefined, { dateStyle: 'long', timeStyle: 'short' });
      rankWinCounts = rankList.probabilityList.rankWinCounts;
    }

    return (
      <div className="mainContent">
        {rankList?.probabilityList?.probabilityList?.loading && <em>Loading...</em>}
        {rankWinCounts?.length > 0 &&
          <div>
            <div>Data last updated: <b>{asOf} (Local Time)</b></div>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Rank</th>
                  <th>Distribution</th>
                </tr>
              </thead>
              <tbody>
                {rankWinCounts.map((item) => {
                  const total = item[1].reduce((acc, innerItem) => acc + innerItem[1], 0);
                  return <tr key={item[0]}>
                    <td>{item[0]}</td>
                    <td>
                      {<ul>
                        {item[1].map((innerItem) => (<div>{innerItem[0]} wins: {Math.round(innerItem[1] * 100 / total)}%<br /></div>))}
                      </ul>}
                    </td>
                  </tr>
                })}
              </tbody>
            </Table>
          </div>
        }
      </div>
    );
  }
}

export default AGLRankStats;
import React from 'react';
import { Table } from 'react-bootstrap';

////////////////////
// Component that encapsulates the secondary AGL Cutoff Stats chart
////////////////////
class AGLCutoffStats extends React.Component {

  render() {
    const { probabilityList } = this.props;
    let cutoffList = probabilityList;
    let cutoffs = [];
    let iterations = 0;
    let asOf = new Date(1999, 11, 31).toLocaleString(undefined, { dateStyle: 'long', timeStyle: 'short' });
    let isTeamBased = false;
    let cutoff = 8;

    if (cutoffList?.probabilityList) {
      var utcDate = new Date(cutoffList.probabilityList.asOf);
      asOf = utcDate.toLocaleString(undefined, { dateStyle: 'long', timeStyle: 'short' });
      let cutoffCounts;
      ({ cutoffCounts, iterations, isTeamBased } = cutoffList.probabilityList);

      // combine same-8th-place lower-9th-place
      cutoffs = cutoffCounts.reduce((acc, [[madeIt, didntMakeIt], count]) => {
        const key = madeIt === didntMakeIt ? madeIt : "< " + madeIt;
        acc[madeIt] ??= {};
        acc[madeIt][key] ??= 0;
        acc[madeIt][key] += count;
        return acc;
      }, {});
      cutoffs = Object.entries(cutoffs).flatMap(([madeIt, map]) => Object.entries(map).map(([didntMakeIt, count]) => [[madeIt, didntMakeIt], count]));
      cutoffs = cutoffs.sort((a, b) => b[0][0] - a[0][0]);

      if (isTeamBased) cutoff = 4;
    }

    return (
      <div className="mainContent">
        {cutoffList?.probabilityList?.loading && <em>Loading...</em>}
        {cutoffs?.length > 0 &&
          <div>
            <div>Data last updated: <b>{asOf} (Local Time)</b></div>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>{cutoff}th place wins</th>
                  <th>{cutoff + 1}th place wins</th>
                  <th>% </th>
                </tr>
              </thead>
              <tbody>
                {cutoffs.map((item) => (
                  <tr key={String(item[0][0]).concat("-", String(item[0][1]))}>
                    <td>{item[0][0]}</td>
                    <td>{item[0][1]}</td>
                    <td>{Math.round(item[1] * 100 / iterations)}%</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        }
      </div>
    );
  }
}

export default AGLCutoffStats;